<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: 89vh">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search issues"
              debounce="750"
              @update="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <b-button
          size="sm"
          variant="flat-primary"
          class="text-nowrap"
          title="Export issues to XLSX"
          @click="$bvModal.show('export-issues-modal')"
        >
          Export Issues
        </b-button>
      </div>
      <v-select class="w-100"
          v-model="selectedLabels"
          :loading="loading"
          :options="bts"
          :multiple="true"
          :close-on-select="false"
          @option:selecting="labelChanged"
          title="Filter by behaviour tree"
          placeholder="Filter by behaviour tree"
        >
        <template #spinner="{ loading }">
          <div
            v-if="loading"
            style="border-left-color: rgba(88, 151, 251, 0.71)"
            class="vs__spinner"
          />
        </template>
      </v-select>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="issue in filteredIssues"
            :key="issue.id"
            tag="li"
            no-body
            @click="updateIssueViewData(issue)"
          >
            <b-media-aside class="media-left mr-50">
              <b-avatar
                class="avatar"
                :class="`bg-${resolveSeverityColor(issue.severity)}`"
                size="30"
              >
                <feather-icon
                  :icon="resolveClassificationIcon(issue.classification)"
                  size="16"
                />
              </b-avatar>
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <span class="font-weight-bolder mb-25">
                    {{ issue.display_id }}
                  </span>
                </div>

                <div class="mail-meta-item">
                  <b-badge
                    class="mr-50"
                    :variant="resolveStatusColor(issue.status)"
                    pill
                  >
                    {{ issue.status }}
                  </b-badge>
                  <b-badge
                    class="mr-50"
                    :variant="resolveSeverityColor(issue.severity)"
                    pill
                  >
                    Severity: {{ issue.severity }}
                  </b-badge>
                  <b-badge
                    class="mr-50"
                    variant="secondary"
                    pill
                  >
                    <feather-icon
                      :icon="resolveClassificationIcon(issue.classification)"
                      class="mr-25"
                    />
                    {{ issue.classification }}
                  </b-badge>

                  <b-badge
                    v-if="issue.behaviour_trees.length > 0"
                    :title="`${issue.behaviour_trees.map(tree => tree.name).join(', ')}`"
                    class="mr-50"
                    variant="warning"
                    pill
                  >
                    <font-awesome-icon
                      :icon="['fas', 'vector-square']"
                      class="icon mr-25"
                    />
                    <span>Behaviour tree<span v-if="issue.behaviour_trees.length > 1">s</span></span>
                  </b-badge>

                  <span class="ml-2 mail-date text-nowrap">Reported: {{ issue.created | shortDate }}</span>
                </div>
              </div>

              <div class="v-html-fixes" v-sanitized-html="issue.name" />
            </b-media-body>
          </b-media>
        </ul>
        <div v-if="loading" class="mt-5 d-flex justify-content-center">
          <b-spinner style="width: 2rem; height: 2rem;" />
        </div>
        <div v-if="loading" class="mt-1 d-flex justify-content-center">
          <h5 class="ml-8">
            Loading Issues...
          </h5>
        </div>
        <div v-if="!loading"
             class="no-results"
             :class="{'show': !issues.length}"
        >
          <h5>
            No Issues Found
          </h5>
          <span v-for="(filter, i) in getFiltersFromRoute()" :key="i">
            <b-badge v-if="filter !== ''" pill variant="primary">{{ filter }}</b-badge>
          </span>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Issue View -->
    <issue-view
      v-if="showIssueDetails"
      :class="{'show': showIssueDetails}"
      :issue-view-data="issueViewData"
      :opened-issue-meta="openedIssueMeta"
      @change-opened-issue="changeOpenedIssue"
      @updated="updateIssueView"
      @close-issue-view="closeIssueView"
    />
    <!-- Left Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <issues-left-sidebar
        :class="{'show': mqShallShowLeftSidebar}"
        @issue-added="fetchIssues"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
    <!-- ./Left Sidebar -->

    <!-- TODO Compose Email Modal -->
    <!--<email-compose v-model="shallShowEmailComposeModal" />-->
    <issue-export />
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
  BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import IssueExport from '@/components/Issues/Modals/IssueExport.vue'
import vSelect from 'vue-select'
import IssuesLeftSidebar from './IssuesLeftSidebar.vue'
import IssueView from './IssueView.vue'
// eslint-disable-next-line import/extensions
import issueHelpers from './issueHelpers.js'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    IssuesLeftSidebar,
    IssueView,

    // Issue Modals
    IssueExport,
    vSelect,
  },
  setup() {
    const { route, router } = useRouter()
    const { resolveClassificationIcon, resolveSeverityColor, resolveStatusColor } = issueHelpers()

    // Issues
    const issues = ref([])
    const loading = ref(true)
    const bts = ref([])
    const selectedLabels = ref([])

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => { searchQuery.value = val })
    // watch(searchQuery, () => fetchIssues())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const getFiltersFromRoute = () => {
      let [classification, severity, status] = ['', '', '']
      if (router.currentRoute.params.filter) {
        [classification, severity, status] = router.currentRoute.params.filter.split('%7C')
      }
      return [classification, severity, status]
    }

    const filteredIssues = computed(() => issues.value.filter(record => (
      !selectedLabels.value
    || selectedLabels.value.length === 0
    || (record.behaviour_trees && record.behaviour_trees.some(bt => bt.name === null))
    || (record.behaviour_trees && record.behaviour_trees.some(bt => selectedLabels.value.includes(bt.name)))
    )))
    const fetchIssues = async () => {
      loading.value = true
      const [classification, severity, status] = getFiltersFromRoute()
      const params = {
        q: router.currentRoute.query.q,
        classification,
        severity,
        status,
        with_bt: true,
      }
      store.dispatch('issues/getIssuesFiltered', params).then(filtered => {
        issues.value = filtered
        loading.value = false
        bts.value = [...new Set(filtered
          .flatMap(record => record.behaviour_trees || [])
          .filter(bt => bt.id && bt.name)
          .map(bt => bt.name)),
        ]
      })
    }

    // ------------------------------------------------
    // Issue Details
    // ------------------------------------------------
    const showIssueDetails = ref(false)
    const issueViewData = ref({})
    const openedIssueMeta = computed(() => {
      const openedIssueIndex = issues.value.findIndex(e => e.id === issueViewData.value.id)
      return {
        hasNextIssue: Boolean(issues.value[openedIssueIndex + 1]),
        hasPreviousIssue: Boolean(issues.value[openedIssueIndex - 1]),
      }
    })
    const updateIssueViewData = issue => {
      issueViewData.value = issue
      store.dispatch('issues/selectIssue', issueViewData.value.id)
        .then(() => {
          showIssueDetails.value = true
        })
    }
    const updateIssueView = () => {
      fetchIssues()
    }

    function labelChanged(label) {
      // not sure why vue-select doesn't deselect tags
      // toggle the tag if it's already in the list (or not)
      if (selectedLabels.value.includes(label)) {
        selectedLabels.value = selectedLabels.value.filter(l => l !== label)
        return
      }
      selectedLabels.value.push(label)
    }

    const changeOpenedIssue = dir => {
      const openedIssueIndex = issues.value.findIndex(e => e.id === issueViewData.value.id)
      const newIssueIndex = dir === 'previous' ? openedIssueIndex - 1 : openedIssueIndex + 1
      issueViewData.value = issues.value[newIssueIndex]
    }

    const closeIssueView = () => {
      issueViewData.value = {}
      showIssueDetails.value = false
    }
    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      fetchIssues()
      // * If someone clicks on filter while viewing detail => Close the email detail view
      showIssueDetails.value = false
      // If there is an issue ID in the route, open single view
      if (routeParams.value.id) {
        // Set the issue prop to have the selected issue ID
        // This is to avoid errors and make sure the focus page has the correct ID for the get issue by ID API call
        issueViewData.value = { id: routeParams.value.id }
        showIssueDetails.value = true
      }
    },
    { immediate: true })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // UI
      perfectScrollbarSettings,

      // Issues
      issues,
      filteredIssues,
      loading,
      fetchIssues,

      // Issue Details
      showIssueDetails,
      issueViewData,
      openedIssueMeta,
      updateIssueViewData,
      changeOpenedIssue,
      closeIssueView,
      updateIssueView,
      bts,
      labelChanged,
      selectedLabels,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,
      getFiltersFromRoute,

      // issueHelpers
      resolveSeverityColor,
      resolveClassificationIcon,
      resolveStatusColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/style.scss";

.v-html-fixes ::v-deep {
  @extend .v-html-fixes-base;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
